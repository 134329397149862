.blog-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: blanchedalmond;
    color: #76453B;
}

.blog-cover-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.blog-cover{
    object-fit: cover;
    align-self: flex-start;
    justify-self: flex-start;
    width: 100%;
    max-height: 380px;
}

.blog-title{
    width: 90%;
    text-align: center;
    padding: 10px;
    background-color: #76453B;
    color: blanchedalmond;
    filter: drop-shadow(0px 0px 4px #76453B);
    border-radius: 20px;
}

.blog-title:first-child{
    background-color: #76453B99;
    top: 0;
    position: absolute;
}

h2.blog-title{
    color: #282828;
    background: none;
    filter: none;
    align-self: flex-start;
    width: fit-content;
    max-width: fit-content;
}

.topics-section{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: stretch;
    gap: 10px;
    padding: 10px;
}