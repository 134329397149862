.blog-topic-card{
    flex: 1 0 auto;
    width: 100%;
    max-width: 400px;
    box-sizing:  border-box;
    display: flex;
    flex-direction: column;
    background-color: #282828;
    color: blanchedalmond;
    padding: 10px;
    border-radius: 14px;
    text-decoration: none;
    transition-duration: 0.6s;
    opacity: 0.8;
    filter: drop-shadow(0px 0px 10px #76453B);
}

.blog-topic-card img{
    border-radius: 10px;
}

.blog-topic-card:hover{
    opacity: 1;
    transform: translateY(-10px);
}

.blog-topic-card h2{
    font-size: 16px;
}

.blog-topic-card h3{
    font-size: 14px;
}