.cart-button{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 20px;
    background: blanchedalmond;
    cursor: pointer;
    transition-duration: 0.6s;
}
.cart-button:hover img{
    transform: scale(1.2);
}

.item-count{
    box-sizing: border-box;
    position: absolute;
    background-color: silver;
    color: black;
    font-weight: bold;
    text-align: center;
    width: 12px;
    padding: 2px;
    bottom: -4px;
    border-radius: 3px;
}

.item-count.hidden{
    display: none;
}

.cart-button img{
    width: 100%;
    object-fit: contain;
    transition-duration: 0.4s;
}