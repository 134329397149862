footer{
    box-sizing: border-box;
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 20px;
    gap: 10px;
    text-align: center;
    border-top: 2px solid #76453B;
    background-color: blanchedalmond;
}

.footer-logo{
    width: 150px;
    border-radius: 20px;
    background-color: #282828;
    padding: 10px;
    filter: drop-shadow(0px 0px 5px black);
}

.footer-links{
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.footer-link-group{
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    min-width: 250px;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.footer-link-group-p{
    align-self: flex-start;
}

.footer-link-group.no-border{
    border: none;
}

.footer-link-group-link{
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-start;
    color: #282828;
    gap: 5px;
    text-align: left;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition-duration: 0.6s;
}

.footer-link-group-link:hover{
    border-color: #282828;
}

.footer-link{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: none;
    color: #76453B;
    border-radius: 10px;
    padding-right: 10px;
    transition-duration: 0.6s;
}

.footer-link:hover{
    background-color: #76453B;
    color: blanchedalmond;
    filter: drop-shadow(0px 0px 5px black);
}

.footer-link img{
    width: 35px;
    filter: drop-shadow(0px 0px 5px blanchedalmond);
}

.footer-credit{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    background-color: #282828;
    padding: 10px;
    border-radius: 20px;
    font-size: 18px;
    filter: drop-shadow(0px 0px 4px #282828);
    transition-duration: 0.6s;
}

.footer-credit:hover{
    font-size: 19px;
}

.footer-credit:hover .credit-img{
    transform: rotate(360deg);
    width: 60px;
}

.credit-img{
    width: 50px;
    transition-duration: 0.6s;
}