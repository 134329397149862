.faq-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: blanchedalmond;
}

.faq-cover{
    width: 100%;
    object-fit: cover;
    max-height: 300px;
    justify-self:  flex-start;
}

.q-section{
    margin-top: 50px;
    margin-bottom: 50px;
    color: #76453B;
    font-weight: bold;
}

.q-section li{
    margin-bottom: 10px;
    border-bottom: 2px solid green;
    padding-bottom: 5px;
    width: 90%;
}

.q-section li ul li{
    margin-bottom: 0px;
    border: none;
    padding-bottom: none;
}

.q-section li ul li:first-child{
    color: green;
}

.best-seller{
    color: blanchedalmond;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 80%;
    padding: 10px;
    border-radius: 20px;
    background-color: #76453B;
    margin-bottom: 20px;
}