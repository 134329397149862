

.page-404{
    box-sizing: border-box;
    padding: 80px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-size: 80px;
    color: #76453B;
}

.sub-div-404{
    box-sizing: border-box;
    width: 90%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border-radius: 20px;
    padding: 10px;
    margin: 0;
}

.h404{
    font-family: 'Lobster', sans-serif;
    font-size: 8rem;
    letter-spacing: 20px;
    margin: 0;
    background-color: blanchedalmond;
    width: 100%;
    text-align: center;
}

.qmark{
    width: 120px;
    filter: drop-shadow(0px 0px 4px #76453B);
    transform: rotateY(180deg);
}

.qmark:last-child{
    transform: rotateY(0deg);
}

.button-holder{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    width: 95%;
}

.button-404{
    text-decoration: none;
    padding: 10px;
    border-radius: 10px;
    background-color: #76453B;
    color: blanchedalmond;
    border: none;
    cursor: pointer;
}