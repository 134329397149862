@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.page-contact{
    box-sizing: border-box;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
    background-color: #e1c78f;
    padding: 0;
}

.contact-background{
    position: absolute;
    z-index: 0;
    background-color: blanchedalmond;
    bottom: 0;
}

.contact-background img{
    width: 100%;
    min-width: 1000px;
    filter: hue-rotate(270deg);
}

.phone-contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contact-form{
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px;
    background-color: rgba(250, 235, 215, 0.7);
    margin: 20px 0px 20px 0px;
    border-radius: 15px;
}

.contact-form input{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 90%;
    padding: 10px;
    border: 1px solid #282828;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
}

.contact-form input:focus{
    outline: none;
}

.contact-form textarea{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 90%;
    padding: 10px;
    border: 1px solid #282828;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    min-height: 200px;
    ;
}

.contact-button{
    box-sizing: border-box;
    width: 90%;
    padding: 10px;
    border-radius: 20px;
    border:none;
    color: white;
    background: linear-gradient(to left, #76453B, rgb(215, 178, 123));
    transition-duration: 0.6s;
    cursor: pointer;
}

.contact-button:hover{
    color: white;
    background-color: green;
    filter: drop-shadow(0px 0px 4px green);
}

.contact-form.response{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.contact-gear{
    animation: rotate 6s linear infinite;
    background-color: #76453B;
    border-radius: 50%;
}

@keyframes rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

@media (min-width: 867.5px) and (max-width: 1100px){
    
}

@media (min-width: 548px) and (max-width: 867px){

}

@media (min-width: 340px) and (max-width: 547px){
    
}

@media (max-width: 339px){
    
}