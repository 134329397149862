@import url('https://fonts.googleapis.com/css2?family=Bitter&family=Lobster&display=swap');

.page-home{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: linear-gradient(#FFEBCDA9, #FFEBCDA9), url('../Resources/TextureBackground.jpg');
    background-size: 250px 250px;
}

.home-cover{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    filter: drop-shadow(0px 5px 5px black);
    opacity: 0.9;
    background-color: #76453B;
}

.home-cover video{
    box-sizing: border-box;
    pointer-events: none;
    width: 100%;
    justify-self: center;
    height: 450px;
    object-fit: cover;
}

.home-cover h1{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #76453B60;
    text-align: center;
    color: blanchedalmond;
    font-family: "Lobster", sans-serif;
    filter: drop-shadow(0px 0px 5px #141414);
    left: 0;
    
}

.intro-home{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    background: linear-gradient(to bottom, blanchedalmond, transparent);
    gap: 10px;
    padding-bottom: 10px;
}

.heading-one{
    width: 100%;
    text-align: center;
    color: #141414;
    font-size: 30px;
}

.intro-feature{
    box-sizing: border-box;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 2px solid green;
}

.intro-feature h2{
    text-align: center;
}

.call-to-action-link-wraper{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.call-to-action-link-wraper img{
    width: 100%;
    object-fit: cover;
    min-height: 350px;
    animation: loadCover 0.6s linear forwards;
    
}

.call-to-action-link-wraper img.hidden{
    display: none;
}

@keyframes loadCover {
    from{
        opacity: 0.1;
        transform: scale(1.2);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

.call-to-action-links{
    box-sizing: border-box;
    position: absolute;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    top: 0px;
    height: 100%;
    border: 2px solid black;
}

.call-to-action-links .heading-one{
    padding: 10px;
    width: 100%;
    background-color: rgba(0,0,0, 0.7);
    color: blanchedalmond;
    margin: 0px;
    position: absolute;
    top: 0px;
}

@media screen and (max-width: 600px) {
    .call-to-action-links .heading-one{
        position: relative;
    }
}

.call-to-action-link{
    box-sizing: border-box;
    min-width: 200px;
    text-decoration: none;
    color: blanchedalmond;
    background-color: #76453B;
    padding: 10px;
    text-align: center;
    filter: drop-shadow(0px 0px 4px black);
    transition-duration: 0.6s;
}

.call-to-action-link:hover{
    background-color: #76453b6f;
    transform: scale(1.1);
}