.lang-pick{
    box-sizing: border-box;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 4px;
    background-color: inherit;
    color: #141414;
    border: 1px solid blanchedalmond;
    border-bottom: none;
    border-top: none;
}

.lang-pick-top{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.lang-button{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: #141414;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition-duration: 0.8s;
    width: 100%;
}

.lang-button:hover{
    background-color: #76453B86;
}

.lang-button .arrow{
    transition-duration: 0.8s;
    margin: 0;
    transform: rotate(0deg);
    width: 20px;
}

.lang-button .arrow.open{
    transform: rotate(180deg);
}

.lang-pick .flag{
    width: 30px;
}

.lang-pick-top p{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin: 0;
}

.lang-pick .dropdown-menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition-duration: 0.8s;
    width: 100%;
    background-color: inherit;
}