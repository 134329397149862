.menu-burger-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 35px;
    height: 35px;
    background:none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid transparent;
    transition-duration: 0.6s;
    margin-left: 10px;
    background-color: rgba(255, 235, 205, 0.5);
}

.menu-burger-button:hover{
    border: 2px solid #282828;
    background-color: rgba(255, 235, 205);
}

.button-line{
    height: 2px;
    width: 28px;
    background-color: #282828;
    transition-duration: 0.6s;
}

.menu-burger-button.selected .button-line{
    position: absolute;
}

.menu-burger-button.selected .button-line:nth-child(1){
    rotate: -45deg;
}

.menu-burger-button.selected .button-line:nth-child(3){
    rotate: 45deg;
}

.menu-burger-button.selected .button-line:nth-child(2){
    display: none;
}
