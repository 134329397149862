.lazy-loading-product{
    position: relative;
    box-sizing: border-box;
    background-color: #28282880;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 300px;
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    backdrop-filter: blur(5px);
    border: 1px solid #282828;
    padding: 5px;
    box-shadow: 0px 0px 4px white;
    margin: 0;
}

@keyframes fadeIn {
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.lazy-loading-product-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    gap: 5px;
    position: absolute;
    bottom: 0;
    height: 130px;
    width: 100%;
    padding: 5px;
}
.lazy-loading-product-name{
    position: relative;
    height: 20px;
    width: 70%;
    background-color: rgba(255, 255, 255, 0.3);
}
.lazy-loading-product-price{
    position: relative;
    width: 50%;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.3);
}

.lazy-loading-product .overlay{
    background-color: inherit;
    height: 100%;
    width: 20px;
    position: absolute;
    transition-duration: 0.2s;
}

