.shop-product{
    box-sizing: border-box;
    position: relative;
    flex: 0 0 auto;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    filter: drop-shadow(none);
    overflow: hidden;
    z-index: 2;
    text-decoration: none;
    transition-duration: 0.6s;
    animation: fadeIn 1s linear;
    border: 2px solid transparent;
}

.shop-product:hover{
    transform: translate(0px, -5px);
    border-bottom-color: black;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}
.shop-product-cover{
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.shop-product-info{
    box-sizing: border-box;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
    width: 100%;
    color: black;
    font-weight: bolder;
}

.prod-name{
    width: fit-content;
    padding: 5px;
    border-radius: 10px;
    font-size: 20px;
}

.shop-product p{
    margin: 0px;
}
.shop-product-price{
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    justify-self: baseline;
}

.shop-product-salePrice{
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
    font-size: larger;
    font-weight: bold;
    letter-spacing: 2px;
    justify-self: baseline;
}

.shop-product-price.sale{
    text-decoration: line-through;
}

.shop-product-price.hidden{
    opacity: 0;
}

.sale-shop-product{
    position: absolute;
    left: 2px;
    top: 2px;
    width: 50px;
    animation: drop 0.5s linear;
    animation-delay: 1s;
    filter: drop-shadow(0px 0px 4px black)
}

.sale-shop-product.hidden{
    display: none;
}

@keyframes drop{
    from{
        transform: scale(2);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

.shop-product button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    z-index: 4;
    background-color: #76453B;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px #76453B);
    color: blanchedalmond;
    font-size: 0px;
    transition-duration: 0.5s;
    gap: 5px;
}

.shop-product button:hover{
    font-size: 14px;
}

.product-button-details{
    position: absolute;
    padding: 10px;
    text-decoration: none;
    color: aliceblue;
    background-color: rgba(0, 128, 0, 0.5);
    border-radius: 10px;
    transition-duration: 0.6s;
    top: 0px;
    left: 0px;
}

.product-button-details:hover{
    transform: translate(10px, 0px)
}

.cart-shop-product{
    width: 25px;
    background-color: blanchedalmond;
    padding: 5px;
    border-radius: 10px;
    transition-duration: 0.5s;
}

.shop-product button:hover .cart-shop-product{
    width: 28px;
}

@media (max-width: 339px){
    .shop-product{
        width: 250px;
    }
    .sale-shop-product{
        width: 35px;
    }
}