.product-list-h{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-list-h h2{
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 5px;
    color: #141414;
    font-size: 30px;
}

.product-list-h .list{
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    gap: 10px;
    width: 100%;
}

.list h2{
    width: 100%;
    background-color: #a52a2a99;
    z-index: 1;
}