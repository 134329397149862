.shop-product-list {
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 2px;
    animation: fadeIn 1s ease-in;
    gap: 10px;
    padding: 10px;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}
