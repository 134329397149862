.shop-page{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: blanchedalmond;
}

.shop-cover{
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    background-color: blanchedalmond;
    transition-duration: 0.6s;
}

.shop-cover .cover-img{
    box-sizing: border-box;
    border: 2px solid black;
    width: 100%;
    object-fit: cover;
    min-height: 300px;
}

.shop-arrow-img{
    width: 40px;
    position: absolute;
    transition-duration: 0.4s;
    cursor: pointer;
    top: 150px;
    background-color: rgba(255, 235, 205, 0.8);
    backdrop-filter: blur(5px);
    animation: bob 2s linear infinite;
    border-radius: 50%;
}

.shop-arrow-img:hover{
    width: 50px;
    animation-play-state: paused;
}

@keyframes bob{
    0%{
        transform: translateY(-5px);
    }50%{
        transform: translateY(5px);
    }100%{
        transform: translateY(-5px);
    }
}

.shop-cover h1{
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 20px;
    background-color: #76453B50;
    color: blanchedalmond;
    width: 80%;
    text-align: center;
    filter: drop-shadow(0px 0px 4px #76453B);
    letter-spacing: 4px;
    height: 100%;
    width: 100%;
    margin: 0;
}

.category-pick-nav{
    z-index: 11;
    box-sizing: border-box;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(255, 235, 205, 0.523);
    padding: 10px;
    top: 10px;
    border-radius: 14px;
    justify-self: flex-start;
}

.categories-title {
    width: 100%;
    margin: 0;
    text-align: center;
}

.category-pick-nav button{
    padding: 10px;
    padding-bottom: 5px;
    background: rgba(255, 235, 205, 0.6);
    border: none;
    letter-spacing: 2px;
    color: #76453B;
    border: 2px solid transparent;
    transition-duration: 0.6s;
    cursor: pointer;
    border-radius: 10px;
}

.category-pick-nav button:hover{
    border-bottom: 2px solid #76453B;
}

.category-pick-nav button.selected{
    border: 2px solid #76453B;
}

@media (min-width: 867.5px) and (max-width: 1100px){
    
}

@media (min-width: 548px) and (max-width: 867px){
    .shop-arrow-img{
        display: none;
    }
    .shop-cover .cover-img{
        border: 1px #76453B solid ;
        width: 100%;
    }
}

@media (min-width: 340px) and (max-width: 547px){
    .shop-arrow-img{
        display: none;
    }
    .shop-cover .cover-img{
        border: 1px #76453B solid ;
        width: 100%;
    }
}

@media (max-width: 339px){
    .shop-arrow-img{
        display: none;
    }
    .shop-page h1{
        font-size: 20px;
    }
    .shop-cover .cover-img{
        border: 1px #76453B solid ;
        width: 100%;
    }
}