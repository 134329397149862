.loading{
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    height: 100%;
    width: 100%;
    opacity: 1;
    transition-duration: 0.6s;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top:0px;
}

.loading.hidden{
    opacity: 0;
    pointer-events: none;
}

.logo-loading{
    width: 85%;
    max-width: 300px;
}
.gear-loading{
    width: 100px;
    animation: rotate 3s linear infinite;
    filter: drop-shadow(0px 0px 4px #ffebcd);
}

.loading p{
    color: blanchedalmond;
    letter-spacing: 3px;
    padding: 10px;
    background-color: #76453B;
    width: 90%;
    max-width: 350px;
    text-align: center;
    filter: drop-shadow(0px 0px 4px #76453B);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}