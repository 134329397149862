.nav-bar{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0;
    background-color: rgb(255, 235, 205);
    backdrop-filter: blur(5px);
    position: sticky;
    z-index: 100;
    box-shadow: 0px 10px 10px #282828;
}

.nav-button-section{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px
}

.logo-burger-container{
    display: flex;
    align-items: center;
    padding: 5px;
}

.nav-logo{
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    height: 60px;
    background-color: #282828;
    transition-duration: 0.2s;
}

.nav-bar-buttons{
    display: flex;
    flex-wrap: wrap;
    left: 0px;
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    position: absolute;
    transition-duration: 0.6s;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(5px);
    overflow: hidden;
}

.nav-bar-buttons.hidden{
    padding: 0px;
    opacity: 0;
}

.nav-bar-buttons a{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    text-decoration: none;
    color: blanchedalmond;
    transition-duration: .6s;
    background-color: transparent;

}

.nav-bar-buttons a:hover{
    background-color: rgba(255, 235, 205, 0.5);
    color: #141414;
}

.nav-bar-buttons a.selected{
    background-color: blanchedalmond;
    color: #141414;
}

@media (max-width: 547px){
    .nav-bar-buttons a{
        width: 100%;
    }
}