.blog-topic-page{
    width: 100%;
    color: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
}

.cover-holder{
    display: flex;
    max-height: 400px;
    overflow: hidden;
}

.blog-topic-cover{
    width: 100%;
    object-fit: cover;
}

.blog-topic-title{
    width: 90%;
}

.blog-topic-section{
    display: flex;
    flex-direction: column;
    width: 95%;
}

.paragraph-image{
    width: 100%;
    max-width: 450px;
    align-self: center;
}

.paragraph-image img{
    width: 100%;
    border-radius: 20px;
}

.blog-link{
    width: fit-content;
    text-decoration: none;
    color: green;
    font-weight: bold;
    letter-spacing: 2px;
    border-bottom: 2px solid green;
}