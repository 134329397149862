.cart-page{
    box-sizing: border-box;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    overflow-y: scroll;
    opacity: 1;
    pointer-events: all;
    transition-duration: 0.6s;

}

.cart-page.hidden{
    pointer-events: none;
    opacity: 0;
}

.cart-close{
    right: 10px;
    top: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 800;
    filter: drop-shadow(0px 0px 2px blanchedalmond);
    border-radius: 10px;
    border: 2px solid #282828;
}

.cart-close:hover img{
    transform: scale(1.3);
}

.cart-logo{
    width: 100px;
    background-color: #282828;
    padding: 1px;
    width: 80%;
    height: 100px;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    object-fit: contain;
}

.cart-close img{
    margin: 0;
    width: 30px;
    object-fit: contain;
    transition-duration: 0.4s;
}

.promo-input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.promo-input input{
    padding: 5px;
    border-radius: 10px;
}

.cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 85%;
    padding: 10px;
    border-radius: 10px;
    background-color: blanchedalmond;
    filter: drop-shadow(0px 0px 4px black);
}

.cart.hidden{
    display: none;
}

.cart-total-show{
    font-weight: bold;
    font-size: 18px;
}

.cart-product-buttons{
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
}

.cart-product button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: max-content;
    height: 25px;
    border: none;
    background: none;
    border: 1px solid #282828;
    transition-duration: 0.3s;
    border-radius: 5px;
}

.cart-product button:hover{
    cursor: pointer;
    transform: scale(1.3);
}

.cart-product button img{
    width: 20px;
}

.cart-product{
    border-bottom: 2px solid #282828;
    padding-bottom: 5px;
}

.empty-cart-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.in-cart-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: #28282899;
    border-radius: 10px;
    padding: 10px;
    transition-duration: 0.6s;
    color: blanchedalmond;
    filter: drop-shadow(0px 0px 5px #282828);
    border: 2px solid transparent;
    cursor: pointer;
}
.in-cart-button:hover{
    background-color: #282828;
    border-color: blanchedalmond;
}

.checkout{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    padding: 10px;
    border-radius: 10px;
    background-color: blanchedalmond;
    filter: drop-shadow(0px 0px 4px black);
    height: max-content;
}

.checkout.hidden{
    display: none;
}

.order-form{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 2px;
}

.order-form select {
    max-width: 300px;
    width: 100%;
    padding: 5px;
    font-size: 13px;
    border: 2px solid #282828;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    background-color: blanchedalmond;
    outline: none;
    cursor: pointer;
    transition-duration: 0.3s;
    color: #282828;
    font-weight: bold;
    letter-spacing: 2px;
}
.order-form select:hover {
    filter: drop-shadow(0px 0px 4px #a52a2a);
}

.order-form select option{
    border: none;
    cursor: pointer;
}

.order-form input{
    margin-bottom: 15px;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #282828;
    filter: drop-shadow(0px 0px 4px #282828);
    background-color: blanchedalmond;
}
.order-form label{
    filter: drop-shadow(0px 0px 4px #282828);
    font-weight: bold;
    letter-spacing: 2px;
    color: #282828;
}

.order-button{
    padding: 5px;
    border-radius: 10px;
    background-color: #282828;
    letter-spacing: 2px;
    color: blanchedalmond;
    cursor: pointer;
    border: 2px solid blanchedalmond;
    filter: drop-shadow(0px 0px 4px #282828);
    width: 250px;
    align-self: center;
    margin-bottom: 60px;
    opacity: 1;
    transition-duration: 0.6s;
}

.order-button.hidden{
    opacity: 0;
    pointer-events: none;
}

.cart-middle{
    width: 80%;
    min-height: 50px;
    height: 50px;
    background-color: #282828;
}

.cart-bottom{
    width: 80%;
    min-height: 50px;
    height: 50px;
    background-color: #282828;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.notification-div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #282828;
    color: blanchedalmond;
    filter: drop-shadow(0px 0px 4px #282828);
    width: 90%;
    border-radius: 20px;
}

.notification-icon{
    width: 100px;
    filter: drop-shadow(0px 0px 4px blanchedalmond);
}

.notification-icon.rotate{
    animation: rotate 1s linear infinite;
}

@keyframes rotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}



