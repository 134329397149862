.social-icon-list{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0;
}

.social-icon-list a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;
    transition-duration: .6s;
}

.social-icon-list a:hover{
    transform: scale(1.1);
    cursor: pointer;
    border-color: black;
}