.product-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    padding-top: 80px;
    padding-bottom: 60px;
    background-color: #76453B;
    background-color: blanchedalmond;
}

.product-page-image{
    box-sizing: border-box;
    width: 100%;
    max-width: 550px;
    border: 2px solid #76453B;
    filter: drop-shadow(0px 0px 6px #76453B);
}

.product-page-info{
    width: calc(90% - 20px);
    max-width: 450px;
    padding: 10px;
}

.product-page-price{
    padding: 5px;
    border-radius: 10px;
    background-color: #76453B;
    color: blanchedalmond;
    letter-spacing: 1px;
}

.product-page-price.hidden{
    display: none;
}

.product-page-add-to-cart-button{
    border-radius: 10px;
    background-color: blanchedalmond;
    border: 2px solid #76453B;
    color: #76453B;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.6s;
    gap: 5px;
    filter: drop-shadow(0px 0px 4px #76453B);
}
.product-page-add-to-cart-button:hover{
    background-color: #76453B;
    color: blanchedalmond;
}

.product-page-cart-img{
    width: 30px;
    border-radius: 10px;
    padding: 5px;
    background-color: blanchedalmond;
}

.sale-product-page{
    width: 50px;
    animation: pop 1s linear;
    filter: drop-shadow(0px 0px 4px #76453B);
}
.sale-product-page.hidden{
    display: none;
}

.product-page-right{
    width: 90%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: blanchedalmond;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px blanchedalmond);
    height: auto;
}

.product-page-right .user-feedback{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 96%;
    padding: 10px;
    height: fit-content;
    max-height: 300px;
    overflow-y: scroll;
}

.user-feedback{
    border: 2px solid #76453B;
}

.user-feedback-title{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

@keyframes pop{
    0%{
        transform: rotate(360deg);
        width: 50px;
        opacity: 1;
    }
    25%{
        width: 70px;
        opacity: 1;
    }
    50%{
        width: 60px;
        opacity: 1;
    }
    75%{
        width: 80px;
        opacity: 1;
    }
    100%{
        transform: rotate(0deg);
        width: 50px;
        opacity: 1;
    }
}

.leave-comment-button{
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    color: #76453B;
    filter: drop-shadow(0px 0px 4px #76453B);
    background-color: blanchedalmond;
    border: none;
    cursor: pointer;
    transition-duration: 0.6s;
}

.leave-comment-button:hover{
    transform: scale(1.1);
}

.feedback-notice{
    width: 95%;
}



