@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.page-about{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    background: linear-gradient(to top, blanchedalmond, transparent);
}

.about-cover{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    height: 300px;
}

.cover-img{
    width: 20%;
    min-width: 150px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.page-about h1{
    margin: 0;
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    font-family: 'Great Vibes', cursive;
    color: blanchedalmond;
    background-color: #282828;
    align-self: center;
    text-align: center;
}

.paragraph{
    margin-left: 10px;
    padding: 2px;
    padding-right: 5px;
    border-radius: 5px;
}

.about-img{
    width: 270px;
    align-self: center;
    border-radius: 10px;
}

.page-about p:last-child{
    align-self: flex-end;
    padding-right: 10px;
    border: none;
}