.App{
    font-family: 'Bitter', serif;
    width: 100%;
    min-width: 280px;
    background-color: antiquewhite;
}

.header-container{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-around;
    z-index: 101;
    background-color: hwb(37 45% 43%);
    padding: none;
}


.socailSpan{
    background-color: inherit;
    padding: 5px;
    border: 1px solid blanchedalmond;
    border-bottom: none;
    border-top: none;
}